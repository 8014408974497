* {
  font-family: Rubik, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

h1,
h2,
h1 span,
h2 span,
.font-oswald {
  font-family: Oswald, sans-serif;
  font-weight: bold;
}

.font-rubik {
  font-family: Rubik, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: url("./assets/images/noise.webp");
  background-color: black;
  animation: animate 0.5s steps(10) infinite;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.bg-vertical-pulse {
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.bg-vertical-pulse::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -100%;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 75%,
    #ffffff 100%
  );
  animation: drop 3s 0s infinite;
}

@keyframes drop {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

.bg-gradient {
  background: linear-gradient(18deg, #b900d9, #00ff58);
  background-size: 600% 600%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  -o-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

/* H1 BG */
.text-gradient {
  background: linear-gradient(18deg, #ff00f7, #00ff9d);
  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-animation: MovingGradient 3s ease infinite;
  -moz-animation: MovingGradient 3s ease infinite;
  -o-animation: MovingGradient 3s ease infinite;
  animation: MovingGradient 3s ease infinite;
}

@-webkit-keyframes MovingGradient {
  0% {
    background-position: 25% 0%;
  }
  50% {
    background-position: 76% 100%;
  }
  100% {
    background-position: 25% 0%;
  }
}
@-moz-keyframes MovingGradient {
  0% {
    background-position: 25% 0%;
  }
  50% {
    background-position: 76% 100%;
  }
  100% {
    background-position: 25% 0%;
  }
}
@-o-keyframes MovingGradient {
  0% {
    background-position: 25% 0%;
  }
  50% {
    background-position: 76% 100%;
  }
  100% {
    background-position: 25% 0%;
  }
}
@keyframes MovingGradient {
  0% {
    background-position: 25% 0%;
  }
  50% {
    background-position: 76% 100%;
  }
  100% {
    background-position: 25% 0%;
  }
}

.bg-circle-shadow {
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0) 60%
  );
}

.bg-shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 26%,
    rgba(0, 0, 0, 0.6) 75%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-shadow-start {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 26%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-shadow-end {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 26%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.skill-card-bg {
  background: rgba(255, 255, 255, 0.05);
}

.bg-b-bar {
  box-shadow: 0 -5px 10px 5px rgba(255, 255, 255, 0.03),
    inset 0 5px 10px 8px rgba(255, 255, 255, 0.05);
}

.round-b-bar {
  border-radius: 20% 20% 0 0;
}

.bg-footer {
  background: rgba(0, 0, 0, 0.5);
}

.bg-gold {
  background: rgb(153, 113, 0);
  background: radial-gradient(
    circle,
    rgba(153, 113, 0, 1) 0%,
    rgba(237, 209, 79, 1) 100%
  );
}

.text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.bg-white-transparent {
  background: rgba(255, 255, 255, 0.7);
}
.bg-black-transparent {
  background: rgba(0, 0, 0, 0.7);
}

.quotes-decoration {
  position: relative;
  z-index: 20;
}

.quotes-decoration::before {
  content: '"';
  font-family: "BIZ UDGothic", sans-serif;
  position: absolute;
  left: -10%;
  top: -10%;
  font-size: 8em;
  opacity: 0.1;
  transform: scaleX(-1);
  z-index: 10;
}

.quotes-decoration::after {
  content: '"';
  font-family: "BIZ UDGothic", sans-serif;
  position: absolute;
  right: -10%;
  top: -10%;
  font-size: 8em;
  opacity: 0.1;
  z-index: 10;
}

.glitch {
  -webkit-animation: glitchAnimation 1s infinite;
  -moz-animation: glitchAnimation 1s infinite;
  -o-animation: glitchAnimation 1s infinite;
  animation: glitchAnimation 1s infinite;
}

@keyframes glitchAnimation {
  40% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
  50% {
    text-shadow: rgba(255, 0, 0, 0.371) -10px 0, rgba(0, 255, 255, 0.322) 10px 0;
  }
  60% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
}
@-webkit-keyframes glitchAnimation {
  40% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
  50% {
    text-shadow: rgba(255, 0, 0, 0.371) -10px 0, rgba(0, 255, 255, 0.322) 10px 0;
  }
  60% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
}
@-moz-keyframes glitchAnimation {
  40% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
  50% {
    text-shadow: rgba(255, 0, 0, 0.371) -10px 0, rgba(0, 255, 255, 0.322) 10px 0;
  }
  60% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
}
@-o-keyframes glitchAnimation {
  40% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
  50% {
    text-shadow: rgba(255, 0, 0, 0.371) -10px 0, rgba(0, 255, 255, 0.322) 10px 0;
  }
  60% {
    text-shadow: rgba(255, 0, 0, 0.711) -5px 0, rgba(0, 255, 255, 0.678) 5px 0;
  }
}

.bg-less-transparent {
  background-color: rgba(255, 255, 255, 0.3);
}

.chat-bubble {
  position: relative;
  z-index: 1;
}

.chat-bubble::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 32px solid;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  z-index: -1;
}

.markdown-format ul {
  list-style: disc outside;
}

.markdown-format ol {
  list-style: decimal outside;
}
